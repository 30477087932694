export const SET_FONT_SANS_SERIF = "SET_FONT_SANS_SERIF";
export const SET_FONT_SIZE = "SET_FONT_SIZE";
export const SET_DISPLAY_FOOTNOTES = "SET_DISPLAY_FOOTNOTES";
export const SET_SHOW_ARCHIVED_CONTENT = "SET_SHOW_ARCHIVED_CONTENT";
export const SET_PRINT_FOOTNOTES = "SET_PRINT_FOOTNOTES";
export const SET_PRINT_HIGHLIGHTS = "SET_PRINT_HIGHLIGHTS";
export const SET_VIDEO_PREFERENCES = "SET_VIDEO_PREFERENCES";
export const SET_IMAGE_PREFERENCES = "SET_IMAGE_PREFERENCES";
export const SET_HIGHLIGHT_STYLE = "SET_HIGHLIGHT_STYLE";

export const _setFontSansSerif = (fontSansSerif) => ({
    type: SET_FONT_SANS_SERIF,
    payload: fontSansSerif,
});

export const _setFontSize = (fontSize) => ({
    type: SET_FONT_SIZE,
    payload: fontSize,
});

export const _setDisplayFootnotes = (displayFootnotes) => ({
    type: SET_DISPLAY_FOOTNOTES,
    payload: displayFootnotes,
});

const _setPrintFootnotes = (printFootnotes) => ({
    type: SET_PRINT_FOOTNOTES,
    payload: printFootnotes,
});

const _setPrintHighlights = (printHighlights) => ({
    type: SET_PRINT_HIGHLIGHTS,
    payload: printHighlights,
});

export const _setShowArchivedContent = (showArchivedContent) => ({
    type: SET_SHOW_ARCHIVED_CONTENT,
    payload: showArchivedContent,
});

const _setImagePreferences = (disableImages) => ({
    type: SET_IMAGE_PREFERENCES,
    payload: disableImages,
});

const _setVideoPreferences = (disableVideos) => ({
    type: SET_VIDEO_PREFERENCES,
    payload: disableVideos,
});

export const setDefaultHighlightStyle = (style) => ({
    type: SET_HIGHLIGHT_STYLE,
    payload: style,
});

// Action Creators

export const setFontSansSerif = (value) => (dispatch) => {
    localStorage.setItem("fontSansSerif", value);
    dispatch(_setFontSansSerif(value));
};

export const setFontSize =
    (fontSize = 1) =>
    (dispatch) => {
        localStorage.setItem("fontSize", fontSize);
        dispatch(_setFontSize(fontSize));
    };

export const setDisplayFootnotes = (value) => (dispatch) => {
    localStorage.setItem("displayFootnotes", value);
    dispatch(_setDisplayFootnotes(value));

    // calling setPrintFootnotes(value); doesn't work and is a strange behavior of... redux?
    // these two lines must be called here
    localStorage.setItem("printFootnotes", value);
    dispatch(_setPrintFootnotes(value));
};

export const setPrintFootnotes = (printFootnotes) => (dispatch) => {
    localStorage.setItem("printFootnotes", printFootnotes);
    dispatch(_setPrintFootnotes(printFootnotes));
};

export const setPrintHighlights = (printHighlights) => (dispatch) => {
    localStorage.setItem("printHighlights", printHighlights);
    dispatch(_setPrintHighlights(printHighlights));
};

export const setShowArchivedContent = (showArchivedContent) => (dispatch) => {
    localStorage.setItem("showArchivedContent", showArchivedContent);
    dispatch(_setShowArchivedContent(showArchivedContent));
};

export const setImagePreferences = (disableImages) => (dispatch) => {
    localStorage.setItem("disableImages", !!disableImages);
    dispatch(_setImagePreferences(!!disableImages));
};

export const setVideoPreferences = (disableVideos) => (dispatch) => {
    localStorage.setItem("disableVideos", !!disableVideos);
    dispatch(_setVideoPreferences(!!disableVideos));
};

export const updateDefaultHighlightStyle = (style) => (dispatch) => {
    localStorage.setItem(
        "highlightStyle",
        JSON.stringify({ ...style, clear: false })
    );
    dispatch(setDefaultHighlightStyle(style));
};
