import { useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";
import {
    selectBookmarksByPid as selectBookmarksByPidBuilder,
    selectErrorByStatus as selectErrorByStatusBuilder,
    selectHighlightAnnotationById as selectHighlightAnnotationByIdBuilder,
    selectI18nHrefById as selectI18nHrefByIdBuilder,
    selectI18nStringById as selectI18nStringByIdBuilder,
} from "../app/selectors";

export const useForceUpdate = () => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    return forceUpdate;
};

// from https://stackoverflow.com/a/57632587/2591007
export const useUpdateEffect = (effect, dependencies = []) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return effect();
        }
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useMountEffect = (effect) => {
    useEffect(effect, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useSelectBookmarksByPid = () => {
    const selectBookmarksByPid = useSelector(selectBookmarksByPidBuilder);

    return selectBookmarksByPid;
};

export const useSelectErrorByStatus = () => {
    const selectErrorByStatus = useSelector(selectErrorByStatusBuilder);

    return selectErrorByStatus;
};

export const useSelectHighlightAnnotationById = () => {
    const selectHighlightAnnotationById = useSelector(
        selectHighlightAnnotationByIdBuilder
    );

    return selectHighlightAnnotationById;
};

export const useSelectI18nHrefById = () => {
    const selectI18nHrefById = useSelector(selectI18nHrefByIdBuilder);

    return selectI18nHrefById;
};

export const useSelectI18nStringById = () => {
    const selectI18nStringById = useSelector(selectI18nStringByIdBuilder);

    return selectI18nStringById;
};
