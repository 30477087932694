import { get } from "@churchofjesuschrist/universal-env";
import isBrowser from "is-in-browser";

const { APP_PATHNAME, APP_HOST } = get();

const splitAppPath = new RegExp(`(^${APP_PATHNAME})?((?:/.*|$))`);
const appBaseRegex = new RegExp(`^${APP_PATHNAME}(?=/|$)`);

const stripLangRegex = /(^\/lib$)|(^\/\w{3}(?=$|\/))/;
const stripOldUriRegex = /^\/(lib|item|content)\//;

const isEncodedRegex = /%[0-9A-Fa-f]{2}/;
const splitEncodedRangesRegex = /[^%]+|(?:%[0-9A-Fa-f]{2})+/g;

export const includesAppBase = (uri) => appBaseRegex.test(uri);

export const prependAppBase = (uri) =>
    !includesAppBase(uri) && /^\//.test(uri) ? `${APP_PATHNAME}${uri}` : uri;

export const removeAppBase = (uri = "") => uri.replace(appBaseRegex, "");

export const toEncodedLowerCase = (str = "") => {
    if (!isEncodedRegex.test(str)) return str.toLowerCase();

    return str.replace(splitEncodedRangesRegex, (chunk) =>
        isEncodedRegex.test(chunk) ? chunk : chunk.toLowerCase()
    );
};

export const cleanUri = (uri = "") => {
    return toEncodedLowerCase(uri).replace(
        splitAppPath,
        (string, appPath = APP_PATHNAME, rest) =>
            appPath +
            rest.replace(stripLangRegex, "$1").replace(stripOldUriRegex, "/")
    );
};

export const removeVerse = (uri = "") => uri.split(".")[0];

export const addHashFromVerse = (uri = "") => {
    uri = uri.split("#")[0];
    let context = uri.split(".").pop();
    let hash = context !== uri ? `#${context.split(/-|\?/)[0]}` : "";

    return `${uri}${hash}`;
};

export const toContentUri = (uri) => removeAppBase(removeVerse(uri));

export const recursiveTransform = (obj, keys = [], func) => {
    for (var property in obj) {
        if (obj[property]) {
            if (typeof obj[property] === "object") {
                recursiveTransform(obj[property], keys, func);
            } else if (keys.includes(property)) {
                if (!/^(?:http|\/\/|#)/.test(obj[property])) {
                    obj[property] = func(obj[property]);
                }
            }
        }
    }
};

export const addPrefix = (obj, key, prefix) => {
    for (var property in obj) {
        if (typeof obj[property] === "object") {
            addPrefix(obj[property], key, prefix);
        } else if (
            property === key &&
            !/^(?:http|\/\/|#)/.test(obj[property])
        ) {
            obj[property] = prefix + obj[property];
        }
    }
};

export const removePrefix = (uri, regex) => {
    if (typeof regex === "string") {
        //convert string to regex
        regex = `/${regex}/`;
    }

    regex = new RegExp(
        `^${regex.toString().replace(/^\/|\/[a-z]*?$/g, "")}`,
        regex.flags
    );

    return uri.replace(regex, "");
};

export const trimPathSegments = (
    path = "",
    { numSegments = 1, fromBack = false }
) => {
    let regex = new RegExp(`(?:/[^/]+){${numSegments}}${fromBack ? "$" : ""}`);

    return path.replace(regex, "");
};

export const fetchPathSegments = (
    path = "",
    { numSegments = 1, fromBack = false }
) => {
    let regex = new RegExp(`(?:/[^/]+){${numSegments}}${fromBack ? "$" : ""}`),
        segments = regex.exec(path);

    return segments ? segments[0] : path;
};

export const makeRelativeUrl = (
    url = "",
    rootDomain = "churchofjesuschrist.org"
) => {
    if (url.startsWith("/") || !url) return url;

    try {
        const { hostname, pathname, search, hash } = new URL(url);

        if (
            hostname.includes(rootDomain) &&
            pathname.startsWith(APP_PATHNAME)
        ) {
            return `${pathname}${search}${hash}`;
        }

        return url;
    } catch (error) {
        return url;
    }
};

export const createLocation = (
    url = "/",
    state = {},
    options = { lang: "eng" }
) => {
    let baseUrl = options?.baseUrl || (isBrowser ? window.location : APP_HOST);
    let {
        href = "",
        origin = "",
        protocol = "",
        host = "",
        hostname = "",
        port = "",
        pathname = "",
        search = "",
        hash = "",
    } = new URL(url, baseUrl);

    let query = url.startsWith("#")
        ? searchToParams(search)
        : { lang: options?.lang, ...searchToParams(search) };
    search = paramsToSearch(query);

    href = `${origin}${pathname}${search}${hash}`;

    return {
        href,
        origin,
        protocol,
        host,
        hostname,
        port,
        pathname,
        search,
        hash,
        state,
        query,
    };
};

export const searchToParams = (search) => {
    const decodedSearch = search.replaceAll("&amp;", "&");
    const urlParams = new URLSearchParams(decodedSearch);

    const params = [...urlParams.entries()].reduce((params, [key, value]) => {
        params[key] = params[key] ? [].concat(params[key], value) : value;

        return params;
    }, {});

    return params;
};

export const paramsToSearch = (params) => {
    let { lang, ...query } = params;

    let search = Object.entries({ lang, ...query })
        .map(([key, value]) =>
            []
                .concat(value || [])
                .filter(Boolean)
                .map((subEntry) => `${key}=${subEntry}`)
        )
        .flat()
        .join("&");

    return search ? `?${search}` : "";
};

export const urlToDotNotation = (url) => {
    let { pathname, query } = createLocation(url);
    let verses = [
        pathname.split(".")[1],
        query.id,
        query.context,
        query.para,
        query.verse,
    ].filter(Boolean);

    pathname = removeVerse(pathname);

    return verses.length ? `${pathname}.${verses.join(",")}` : pathname;
};

export const dotToIdNotation = (dotNotationUrl) => {
    let url = new URL(dotNotationUrl);

    let [pathname, id] = url.pathname.split(".");

    url.pathname = pathname;

    id = [].concat(url.searchParams.get("id") || [], id).join(",");
    id && url.searchParams.set("id", id);

    return url.toString();
};
