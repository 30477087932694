import { useEffect, useRef } from "react";
import connectLocalSettings from "../../containers/local-settings";
import connectNotifications from "../../containers/notifications";
import { composeConnectors } from "../../../util/redux-connector-utils";

const LocalSettings = ({
    updateSeenNotifications,
    setFontSansSerif,
    setFontSize,
    setDisplayFootnotes,
    setImagePreferences,
    setPrintFootnotes,
    setPrintHighlights,
    setShowArchivedContent,
    setVideoPreferences,
    updateDefaultHighlightStyle,
}) => {
    const libraryLookup = useRef({});

    useEffect(() => {
        const handlePrintFootnotes = (printFootnotesValue) => {
            setPrintFootnotes(printFootnotesValue);

            printFootnotesValue
                ? document.body.classList.replace(
                      "print-hide-footnotes--override",
                      "print-show-footnotes--override"
                  )
                : document.body.classList.replace(
                      "print-show-footnotes--override",
                      "print-hide-footnotes--override"
                  );
        };

        const handlePrintHighlights = (printHighlightsValue) => {
            setPrintHighlights(printHighlightsValue);
            printHighlightsValue
                ? document.body.classList.remove("print-hide-highlights")
                : document.body.classList.add("print-hide-highlights");
        };

        const handleFontSize = (passedFontSize) => {
            let localStorageFontSize =
                JSON.parse(localStorage.getItem("fontSize")) ?? 1;

            let newFontSize = passedFontSize;

            if (localStorageFontSize !== newFontSize) {
                newFontSize = localStorageFontSize;
            }

            if (
                newFontSize < 0.6 ||
                newFontSize > 1.8 ||
                newFontSize === undefined ||
                newFontSize === isNaN()
            ) {
                newFontSize = "1";
                setFontSize(newFontSize);
            } else if (newFontSize > 0.6 || newFontSize < 1.8) {
                newFontSize = newFontSize.toString();
                setFontSize(newFontSize);
            }
        };

        const handleDisplayFootnotes = (displayFootnotesValue) => {
            setDisplayFootnotes(displayFootnotesValue);

            displayFootnotesValue
                ? document.body.classList.replace(
                      "print-hide-footnotes",
                      "print-show-footnotes"
                  )
                : document.body.classList.replace(
                      "print-show-footnotes",
                      "print-hide-footnotes"
                  );
        };

        libraryLookup.current = JSON.parse(
            localStorage.getItem("libraryLookup") || "{}"
        );

        // Remove this when run into after May 1, 2024 - See https://github.com/ICSEng/gospel-library-fe/pull/537/files#r1520497518
        if (localStorage.getItem("footnotesHidden")) {
            let theFootnotesHiddenVar =
                localStorage.getItem("footnotesHidden") === "true";
            localStorage.setItem("displayFootnotes", !theFootnotesHiddenVar);
            localStorage.removeItem("footnotesHidden");
        }

        let fontSansSerif =
            JSON.parse(localStorage.getItem("fontSansSerif")) ?? false;
        let displayFootnotes =
            JSON.parse(localStorage.getItem("displayFootnotes")) ?? true;
        let storedFontSize = JSON.parse(localStorage.getItem("fontSize")) ?? 1;
        let storedFontSizeNum = Number(storedFontSize);

        let printFootnotes =
            JSON.parse(localStorage.getItem("printFootnotes")) ?? true;
        let printHighlights =
            JSON.parse(localStorage.getItem("printHighlights")) ?? true;
        let disableImages = JSON.parse(localStorage.getItem("disableImages"));
        let disableVideos = JSON.parse(localStorage.getItem("disableVideos"));
        let highlightStyle = JSON.parse(localStorage.getItem("highlightStyle"));
        let showArchivedContent =
            JSON.parse(localStorage.getItem("showArchivedContent")) ?? false;
        let seenNotifications =
            JSON.parse(localStorage.getItem("seenNotifications")) || [];

        document.body.classList.add("print-show-footnotes");

        handleDisplayFootnotes(displayFootnotes);
        handlePrintFootnotes(printFootnotes);
        handlePrintHighlights(printHighlights);
        disableImages && setImagePreferences(disableImages);
        disableVideos && setVideoPreferences(disableVideos);
        highlightStyle && updateDefaultHighlightStyle(highlightStyle);
        setFontSansSerif(fontSansSerif);
        handleFontSize(storedFontSizeNum);
        setShowArchivedContent(showArchivedContent);
        seenNotifications.length && updateSeenNotifications(seenNotifications);

        const handleStorage = (event) => {
            switch (event.key) {
                case "fontSansSerif":
                    setFontSansSerif(JSON.parse(event.newValue));

                    break;
                case "fontSize":
                    handleFontSize(JSON.parse(event.newValue));

                    break;
                case "displayFootnotes":
                    handleDisplayFootnotes(JSON.parse(event.newValue));

                    break;
                case "printFootnotes":
                    handlePrintFootnotes(JSON.parse(event.newValue));

                    break;
                case "printHighlights":
                    handlePrintHighlights(JSON.parse(event.newValue));

                    break;
                case "disableImages":
                    setImagePreferences(JSON.parse(event.newValue));

                    break;
                case "disableVideos":
                    setVideoPreferences(JSON.parse(event.newValue));

                    break;
                case "highlightStyle":
                    updateDefaultHighlightStyle(JSON.parse(event.newValue));

                    break;
                case "libraryLookup":
                    libraryLookup.current = JSON.parse(event.newValue);

                    break;
                case "notifications":
                    updateSeenNotifications(JSON.parse(event.newValue));

                    break;
                case "showArchivedContent":
                    setShowArchivedContent(JSON.parse(event.newValue));

                    break;

                default:
                    break;
            }
        };

        window.addEventListener("storage", handleStorage);

        return () => window.removeEventListener("storage", handleStorage);
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

let connectAll = composeConnectors(connectLocalSettings, connectNotifications);

export default connectAll(LocalSettings);
