import { connect } from "react-redux";
import * as localSettingsActions from "../actions/localSettings";
import { selectToc } from "../selectors";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
    let location = ownProps.location;
    let book = selectToc(state, location);

    return {
        defaultStyle: state.localSettings.defaultStyle,
        displayFootnotes: state.localSettings.displayFootnotes,
        showArchivedContent: state.localSettings.showArchivedContent,
        location,
        lang: ownProps.lang,
        bookUri: book.uri || "",
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        ownProps,
    };
};

const connectLocalSettingsBase = connect(
    mapStateToProps,
    {
        ...localSettingsActions,
    },
    mergeProps
);

export default (Component) => withRouter(connectLocalSettingsBase(Component));
