import styles from "./LowServiceBanner.css";
import { useDispatch, useSelector } from "react-redux";
import { selectI18nStringById as selectI18nStringByIdBuilder } from "../../selectors";
import { SecondaryButton } from "../../theming/components/eden-buttons";
import { InfoBanner } from "../../theming/components/eden-banner";
import { triggerNotification } from "../../actions/notifications";
import {
    setVideoPreferences,
    setImagePreferences,
} from "../../actions/localSettings";
import analytics from "../../../util/analytics";
import { OPTIMIZE_SETTINGS_NOTIFICATION } from "../../../util/notificationConstants";

export const LowServiceBanner = ({ onClose, props }) => {
    const selectI18nStringById = useSelector(selectI18nStringByIdBuilder);
    const dispatch = useDispatch();

    const optimize = () => {
        dispatch(setVideoPreferences(true));
        dispatch(setImagePreferences(true));
        dispatch(triggerNotification(OPTIMIZE_SETTINGS_NOTIFICATION));

        analytics.fireSettingsEvent({
            component: {
                info: {
                    location: "banner",
                    name: "disable images, disable videos",
                },
                category: {
                    type: "low bandwidth",
                },
            },
        });

        onClose();
    };

    return (
        <InfoBanner
            action={
                <SecondaryButton onClick={optimize} small>
                    {selectI18nStringById("lowServiceBannerButton")}
                </SecondaryButton>
            }
            className={styles.lowServiceBanner}
            description={selectI18nStringById("lowServiceBannerMesssage")}
            onClose={onClose}
            {...props}
        />
    );
};
