import styleContainer from "../../containers/styles";
import { Helmet } from "react-helmet";

const onChangeClientState =
    (styleSheetLoaded, removeStyleSheet) =>
    (newState, addedTags, removedTags) => {
        (addedTags.linkTags || []).forEach((link) => {
            if (link.rel.includes("stylesheet")) {
                link.addEventListener("load", () => styleSheetLoaded(link), {
                    once: true,
                });
            }
        });

        (removedTags.linkTags || []).forEach((link) => {
            if (link.rel === "stylesheet") {
                removeStyleSheet(link);
            }
        });
    };

const StyleSheetLink = (link) => (
    <link
        {...link}
        key={link.href}
        rel="preload stylesheet"
        as="style"
        type="text/css"
        href={link.href}
    />
);

const StyleManager = ({
    currentLinks = [],
    removeStyleSheet,
    styleSheetLoaded,
}) => (
    <Helmet
        onChangeClientState={onChangeClientState(
            styleSheetLoaded,
            removeStyleSheet
        )}
    >
        {currentLinks.map(StyleSheetLink)}
    </Helmet>
);

export default styleContainer(StyleManager);
